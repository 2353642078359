<template>
  <div class="reviews">
    reviews
  </div>
</template>

<script>

export default {
  name: 'Reviews'
}

</script>

<style lang="scss" scoped>

</style>